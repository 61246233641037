import { getTransactions } from '@services/thansen/transactions';
import Progress from '@types/Progress';
import dayjs from 'dayjs';

export default {
  name: 'TransactionsContainer',

  props: {
    itemsPerPage: {
      type: Number,
      default: 100,
    },
    routing: {
      type: Boolean,
      default: false,
    },
    searchableAttributes: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      transactions: [],
      status: '',
      page: 1,
      startDate: dayjs()
        .subtract(1, 'year')
        .add(1, 'day')
        .toDate(),
      endDate: dayjs().toDate(),
    };
  },

  computed: {
    pageTransactions() {
      return this.transactions.slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    totalPages() {
      return Math.floor(this.transactions.length / this.itemsPerPage);
    },

    total() {
      return this.transactions.length;
    },
  },

  created() {
    this.readRoute();
    this.fetchTransactions();
  },

  methods: {
    readRoute() {
      if (this.$route.query.from) this.startDate = new Date(this.$route.query.from);
      if (this.$route.query.to) this.endDate = new Date(this.$route.query.to);
      if (this.$route.query.page) {
        const page = parseInt(this.$route.query.page);
        if (page) this.page = page;
      }
    },
    setPage(page) {
      this.page = page;
      // TODO: If page is 1 then remove the page query.
      if (this.$route.query.page || page > 1) {
        this.$router.replace({
          name: this.$route.name,
          query: { ...this.$route.query, page: page == 1 ? undefined : page },
        });
      }
    },
    setDateRange(range) {
      if (this.isSameDay(this.startDate, range.start) && this.isSameDay(this.endDate, range.end))
        return;
      this.startDate = range.start;
      this.endDate = range.end;
      this.updateRouteDateRange();
      this.setPage(1);
      this.fetchTransactions();
    },

    updateRouteDateRange() {
      const query = { ...this.$route.query };
      query.from = dayjs(this.startDate).format('YYYY-MM-DD');
      query.to = dayjs(this.endDate).format('YYYY-MM-DD');
      this.$router.replace({ name: this.$route.name, query });
    },
    isSameDay(a, b) {
      if (a === b) return true;
      if (a === null || b === null) return false;
      return (
        a.getFullYear() === b.getFullYear() &&
        a.getMonth() === b.getMonth() &&
        a.getDate() === b.getDate()
      );
    },
    isSameObject(a, b) {
      if (a === b) return true;
      if (a === null || b === null) return false;
      if (Object.keys(a).length !== Object.keys(b).length) return false;
      return Object.keys(a).every(key => b[key] && a[key] === b[key]);
    },
    fetchTransactions() {
      this.status = Progress.WORKING;

      getTransactions(this.getParams())
        .then(data => {
          this.transactions = data;
          this.status = Progress.COMPLETE;
        })
        .catch(error => {
          this.status = Progress.ERROR;
        });
    },
    getParams() {
      const params = {};
      if (this.startDate) params.start_date = dayjs(this.startDate).format('DD-MM-YYYY');
      if (this.endDate) params.end_date = dayjs(this.endDate).format('DD-MM-YYYY');
      if (this.refinements) params.refinements = this.getParsedRefinements();
      return params;
    },
  },

  render() {
    return this.$scopedSlots.default({
      transactions: this.transactions,
      status: this.status,
      pageTransactions: this.pageTransactions,
      totalPages: this.totalPages,
      total: this.total,
      page: this.page,
      setPage: this.setPage,
      startDate: this.startDate,
      endDate: this.endDate,
      setDateRange: this.setDateRange,
    });
  },

  provide() {
    return {
      transactionsContainer: this,
    };
  },
};
