import { Api } from '../';

/**
 *
 * @param {Object} params limit, start, show_only_open, start_date, end_date
 */
export function getTransactions(params) {
  return new Promise((resolve, reject) => {
    Api.get(`/transactions`, { params })
      .then(response => {
        if (response.data && response.data.data) {
          resolve(response.data.data);
        } else {
          resolve(response.data && response.data.data ? response.data.data : []);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
