var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ResizeObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var width = ref.width;
return [_c('div',{staticClass:"PostingsScene"},[_c('h2',{staticClass:"PostingsScene__title"},[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('TransactionsContainer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pageTransactions = ref.pageTransactions;
var setDateRange = ref.setDateRange;
var startDate = ref.startDate;
var endDate = ref.endDate;
return [_c('div',[_c('div',{staticClass:"PostingsScene__topBar"},[_c('DateRangePicker',{staticClass:"PostingsScene__dateRangePicker",attrs:{"startDate":startDate,"endDate":endDate,"show-as-modal":width < 760},on:{"change":setDateRange}}),_c('Button',{staticClass:"PostingsScene__printButton",attrs:{"icon":_vm.ButtonIcons.PRINT},on:{"click":_vm.printPage}},[_vm._v(_vm._s(_vm.$t('order.controls.print')))])],1),_c('p',{staticClass:"PostingsScene__printInfo"},[_vm._v(" "+_vm._s(_vm.$t('from'))+" "),_c('Time',{attrs:{"format":"L","datetime":startDate.toISOString()}}),_vm._v(" "+_vm._s(_vm.$t('to').toLowerCase())+" "),_c('Time',{attrs:{"format":"L","datetime":endDate.toISOString()}})],1),_c('Transactions')],1)]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }