<template>
  <DetailsCard :details="details" class="TransactionsCard" />
</template>

<script>
import DetailsCard, { Formats } from '@scenes/SelfserviceScene/components/DetailsCard';

export default {
  name: 'TransactionsCard',

  components: {
    DetailsCard,
  },

  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },

  computed: {
    details() {
      return [
        {
          header: this.$t('order.invoice_number'),
          label: this.transaction.invoice_number,
          format: Formats.LINK,
          linkTo: this.transaction.invoice_token && {
            name: '/selfservice/orders/:id',
            params: { id: this.transaction.invoice_token },
          },
        },
        {
          header: this.$t('date'),
          label: this.transaction.date,
          format: Formats.DATE,
          dateFormat: 'L',
        },
        {
          header: this.$t('transaction.settled'),
          label: this.transaction.date_closed,
          format: Formats.DATE,
          dateFormat: 'L',
        },
        {
          header: this.$t('transaction.due_date'),
          label: this.transaction.due_date,
          format: Formats.DATE,
          dateFormat: 'L',
        },

        {
          header: this.$t('transaction.amount'),
          label: this.transaction.amount,
          format: Formats.PRICE,
        },
        {
          header: this.$t('transaction.remaining_amount'),
          label: this.transaction.remaining_amount,
          format: Formats.PRICE,
        },
      ];
    },
  },
};
</script>
