<template>
  <ResizeObserver v-slot="{ width }">
    <div class="PostingsScene">
      <h2 class="PostingsScene__title">{{ $route.meta.title }}</h2>
      <TransactionsContainer v-slot="{ pageTransactions, setDateRange, startDate, endDate }">
        <div>
          <div class="PostingsScene__topBar">
            <DateRangePicker
              class="PostingsScene__dateRangePicker"
              @change="setDateRange"
              :startDate="startDate"
              :endDate="endDate"
              :show-as-modal="width < 760"
            />
            <Button
              class="PostingsScene__printButton"
              :icon="ButtonIcons.PRINT"
              @click="printPage"
              >{{ $t('order.controls.print') }}</Button
            >
          </div>
          <p class="PostingsScene__printInfo">
            {{ $t('from') }} <Time format="L" :datetime="startDate.toISOString()" />
            {{ $t('to').toLowerCase() }} <Time format="L" :datetime="endDate.toISOString()" />
          </p>
          <Transactions />
        </div>
      </TransactionsContainer>
    </div>
  </ResizeObserver>
</template>

<script>
import ResizeObserver from '@components/ResizeObserver';
import TransactionsContainer from '@scenes/SelfserviceScene/containers/TransactionsContainer';
import DateRangePicker from '@components/DateRangePicker';
import Transactions from './components/Transactions';
import Time from '@components/Time';

import { Button, colors as buttonColors, icons as ButtonIcons } from '@components/Buttons';

export default {
  name: 'PostingsScene',
  enums: {
    ButtonIcons,
  },
  components: {
    ResizeObserver,
    TransactionsContainer,
    DateRangePicker,
    Transactions,
    Button,
    Time,
  },
  methods: {
    printPage() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
.PostingsScene {
  padding: 0 20px;
  @media screen AND (min-width: $laptopS) {
    padding: 0;
  }

  .PostingsScene__title {
    @include maxBreakpoint(760) {
      margin-bottom: 15px;
    }
  }
  .PostingsScene__printButton {
    @include maxBreakpoint(760) {
      display: none;
    }
  }
  .PostingsScene__topBar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  .PostingsScene__printInfo {
    display: none;
    @media print {
      display: block;
    }
  }
}
</style>
