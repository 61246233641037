<template>
  <div class="Transactions">
    <!--<div class="Transactions__head">
      <h2 class="Transactions__title">{{ $t('order') }} ({{ transactionsContainer.total }})</h2>
    </div>-->
    <SkeletonTransition
      :ready="
        transactionsContainer.status === Progress.COMPLETE ||
          transactionsContainer.status === Progress.ERROR
      "
    >
      <template #default>
        <Alert
          v-if="transactionsContainer.status === Progress.ERROR"
          :level="AlertLevel.ERROR"
          hideIcon
        >
          {{ $t('transactions.message.download_error') }}
        </Alert>
        <div class="Transactions__cards" v-else-if="transactionsContainer.total > 0">
          <TransactionsCard
            v-for="transaction in transactionsContainer.pageTransactions"
            :key="transaction.invoice_number"
            :transaction="transaction"
          />
        </div>
        <p class="Transactions__noResult" v-else>
          {{ $t('transactions.message.found_no_transactions') }}
        </p>
        <!--<TransactionsTable :page="page" :items-per-page="itemsPerPage" />-->
      </template>
      <template #skeleton>
        <div class="Transactions__cards">
          <DetailsCardSkeleton v-for="i in 4" :key="i" />
        </div>
      </template>
    </SkeletonTransition>

    <Pagination
      v-if="transactionsContainer.totalPages > 1"
      :page="transactionsContainer.page"
      :total="transactionsContainer.totalPages"
      class="Transactions__pagination"
      @change="transactionsContainer.setPage"
    />
  </div>
</template>

<script>
import { SkeletonTransition, DetailsCardSkeleton } from '@components/Skeleton';
import Alert, { AlertLevel } from '@components/Alert';
import Pagination from '@components/Pagination2';
import Input from '@scenes/SelfserviceScene/components/Input';
import TransactionsCard from './components/TransactionsCard';
import Progress from '@types/Progress';

export default {
  name: 'Transactions',

  components: {
    SkeletonTransition,
    DetailsCardSkeleton,
    Alert,
    Pagination,
    Input,
    TransactionsCard,
  },

  enums: {
    AlertLevel,
    Progress,
  },

  inject: {
    transactionsContainer: {
      default() {
        console.error('Missing TransactionsContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.Transactions {
  .Transactions__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;

    @include maxBreakpoint(760) {
      align-items: flex-start;
      flex-direction: column;
      gap: 15px;
    }
  }

  .Transactions__title {
    margin: 0;
    font-size: 24px;
  }

  .Transactions__status {
    color: #808080;
  }

  .Transactions__cards {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .Transactions__pagination {
    justify-content: center;
    margin-top: 20px;
  }

  .Transactions__noResult {
    font-size: 18px;
    color: #aeaeae;
    text-align: center;
    margin-top: 60px;
  }

  .Transactions__search {
    width: 256px;

    @include maxBreakpoint(760) {
      width: 100%;
    }
  }
}
</style>
